<template>
	<div>
		<div class="download-content">
			<div>
				<spinner v-if="download == 'busy'" />
                <h1 v-if="download == 'ready'">{{programTitle}}</h1>
                <div v-if="download == 'ready'" class="acyearRow">{{$t('programList.academicYear')}} : {{$route.params.acYear}}</div>
				<phase v-if="download == 'ready'" :phase="blob" />
			</div>
		</div>
	</div>
</template>
<script>
/*eslint-disable */
import Program from "common/components/Pdf/Program.vue";
import Phase from "common/components/Pdf/Phase.vue";
import Spinner from "common/components/General/Spinner.vue";
import { mapState } from "vuex";
//import Course from "common/components/Pdf/Course.vue";

export default {
	components: { Program, Phase, Spinner },
	data() {
		return {
            blob: {},
            download: "idle",
            programTitle: ""
		};
        },
    computed: {
		...mapState(["program"])
    },
    created() {
        this.ExportPhasing()
        },

	methods: {
        async ExportPhasing() {
            var model = this;
            model.download = "busy";
            var id = this.$route.params.programSlug;
            var params = this.$route.params
            params.forcheCache = true;
            var acYear = this.$route.params.acYear
            var phasingValue = this.$route.params.phaseValue;
            var key = acYear + id;
            await model.$store.dispatch("getProgram", params);
            await model.$store.dispatch("getPhasing", params);
            model.programTitle = model.$store.state.program[key].details.Title;
            var phasings = model.$store.state.program[key].phasings;
            var phaseValue = this.identifyingProperty ? this.identifyingProperty : this.$route.params.phaseValue;
            var phasing = null;
				for (var i in phasings) {
					var p = phasings[i];
					var null_id = "00000000-0000-0000-0000-000000000000";
					if (p.PhasingValue == phaseValue) {
						if (p.Id == null_id) p.Id = null;
						phasing = p;
					}
            }
            this.blob = phasing;
			var params = { programSlug: id, phaseValue: phasingValue, acYear: acYear, forceCache: true }
            await model.$store.dispatch("getBaseitems", params);
			phasing.Groupings = model.$store.state.program[key].baseitems[phasingValue];
			for (var index in phasing.Groupings) {
				var group = phasing.Groupings[index]
				for (var baseitem in group.Baseitems) {
                    var baseitemid = group.Baseitems[baseitem].BaseItemId.substring(4);
                    var params3 = {programSlug: id, baseItemId: baseitemid, acYear: acYear}
					await model.$store.dispatch("getCourse", params3);
                    group.Baseitems[baseitem].Course = model.$store.state.program[key].courses[baseitemid];
				}
			}
            
			model.download = "ready";	
            setTimeout(function () {
				model.CallPrintFunction()
            }, 2000)
		},
		SortBaseItems(baseItems) {
			var model = this;
			if (baseItems === null) return;
			//sort base items by grouping
			var groupings = new Object();
			baseItems.forEach(function(item) {
				for (var i in item.Groupings) {
					var group = item.Groupings[i];
					if (!groupings[group]) {
						groupings[group] = { title: group, baseItems: [] };
					}
					groupings[group].baseItems.push(item);
				}
			});
			// sort, and split into rows of two
			var groupingArray = [];
			for (var grouping in groupings) {
				groupings[grouping].baseItems.sort(function(a, b) {
					return model.CompareBaseItems(a, b);
				});
				groupingArray.push(groupings[grouping]);
			}
			groupingArray.sort(function(a, b) {
				return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
			});
			return groupingArray;
        },
		CallPrintFunction() {
			window.print()
        },
		CompareBaseItems(ob1, ob2) {
			if (ob1.SortId === null && ob2.SortId !== null) return 1;
			else if (ob1.SortId !== null && ob2.SortId === null) return -1;

			if (ob1.SortId > ob2.SortId) {
				return 1;
			} else if (ob1.SortId < ob2.SortId) {
				return -1;
			}

			if (ob1.Title < ob2.Title) {
				return -1;
			} else if (ob1.Title > ob2.Title) {
				return 1;
			} else {
				return 0;
			}
        }

	}
};
</script>
<style lang="less" scoped>
    #app {
			.download

		{
			display: flex;
			width: 100%;
			flex-direction: column;
		}
		.download-link {
			display: inline;
			padding-right: 0.25em;
		}
    }
</style>
<style lang="less">
	@import "../assets/print.less";
	.title-language,
	.hero{
        visibility:hidden;
		height: 0px;
	}
	h3, h4, h5 {
		font-weight: bold !important;
	}
</style>
